import React, { useEffect, useState } from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { withStyles } from '@mui/styles';
// import Data2020 from './Data2020';
// import Data2021 from './Data2021';
// import Data2022 from './Data2022';
// import Data2023 from './Data2023';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import RenderGallery from './RenderGallery';
import { galleryData } from '../../SiteData/Gallery/GalleryData';
import HeaderBanner from '../../Utils/HeaderBanner';
import { headerBannerBg } from '../../assets/Images/Images';

const styles = {
  container: {
    width: '78%',
    margin: '0 auto',
    // paddingTop: '3rem',
    // marginTop: '4rem',
  },
  activeTabStyles: {
    padding: '10px 36px',
    color: '#fff',
    background: '#363847',
    borderRadius: 25,
    cursor: 'pointer',
    // fontFamily: 'Helvetica For TargetRegular',
    border: 'none',
    marginRight: '20px',
    marginBottom: '20px',
    fontSize: 16,
    '&:focus-visible': {
      borderRadius: 50,
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
  },
  tabStyles: {
    marginBottom: '20px',
    padding: '10px 36px',
    borderRadius: 25,
    cursor: 'pointer',
    color: '#353743',
    border: '1px solid #363847',
    marginRight: '20px',
    // fontFamily: 'Helvetica For TargetRegular',
    background: '#ffffff',
    fontSize: 16,
    '&:focus-visible': {
      borderRadius: 50,
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
  },
};

const Gallery = ({ classes }) => {
  const [selectedYear, setSelectedYear] = useState(2024);
  // const mobile = useMediaQuery((theme) => theme.breakpoints.up('xs') && theme.breakpoints.down('sm'));
  useEffect(() => {
    document.title = 'Target Elevate - Gallery';
  }, []);
  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  const isMobileAppRequest = useSelector((state) => state.invenue.open);
  const years = [2020, 2021, 2022, 2023, 2024];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTab = useMediaQuery(theme.breakpoints.between(600, 1025));
  return (
    <>
      <Helmet>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <title>Target Elevate - Gallery</title>
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-T7YMEDLNTX'></script>
        <script>
          {`  window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-T7YMEDLNTX');`}
        </script>

        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript>
          {`
                    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WLZJSJZ"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
        {/* <!-- End Google Tag Manager (noscript) --> */}
      </Helmet>
      <HeaderBanner marginTop={isMobile ? '20px' : isTab ? '5%' : '30px'} headerTxt={'gallery'} headerBannerBg={headerBannerBg} />
      <Grid className={classes.container} style={{ paddingTop: isMobileAppRequest ? '2rem' : '3rem' }}>
        <Grid container style={{ marginBottom: 20 }}>
          {years.map((year) => (
            <button
              key={year}
              aria-label={`${year} elevate event videos`}
              style={selectedYear === year ? styles.activeTabStyles : styles.tabStyles}
              onClick={() => setSelectedYear(year)}
            >
              {year}
            </button>
          ))}
        </Grid>
        {selectedYear && <RenderGallery eventYear={selectedYear} galleryData={galleryData} />}
        {selectedYear === 2024 && (
          <div style={{ textAlign: 'center', margin: '10px 0' }}>
            <Typography>
              Disclaimer : Video transcripts will be available on request. Please write to{' '}
              <a href='mailto:Accessibility-TII@target.com'>Accessibility-TII@target.com</a> &{' '}
              <a href='mailto:elevate@target.com'>elevate@target.com</a>
            </Typography>
          </div>
        )}
      </Grid>
    </>
  );
};
export default withStyles(styles)(Gallery);
