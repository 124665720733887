import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import apiConfig from '../../apiConfig';
import DOMPurify from 'dompurify';

const VideoInFullPage = () => {
  let { search } = useLocation();
  search = search.replace(/%3D/g, '=');
  search = search.replace(/%26/g, '&');
  const searchParams = new URLSearchParams(search);
  const { imagesBucket } = apiConfig;
  const vidsrc = imagesBucket + '/' + DOMPurify.sanitize(searchParams.get('vidsrc')).replace(/[^a-zA-Z0-9-.]/g, '');
  const vttSrc = imagesBucket + '/' + DOMPurify.sanitize(searchParams.get('track')).replace(/[^a-zA-Z0-9-.]/g, '');
  const posterSrc = imagesBucket + '/' + DOMPurify.sanitize(searchParams.get('poster')).replace(/[^a-zA-Z0-9-.]/g, '');

  const ref = useRef();
  const playError = (error) => {
    ref.current.currentTime = 0;
    ref.current.load();
  };
  return (
    <div style={{ display: 'flex' }}>
      <video
        playsInline
        style={{
          width: '100%',
          height: '90vh',
          padding: '40px'
        }}
        // autoPlay
        poster={posterSrc}
        crossOrigin='anonymous'
        controls={true}
        disablePictureInPicture
        controlsList='nodownload'
        onError={playError}
        ref={ref}
      >
        <source src={vidsrc} />
        {<track label='English' kind='captions' srcLang='en' src={vttSrc} />}
      </video>
    </div>
  );
};

export default VideoInFullPage;
