import React from 'react';
import { Box, Card, CardContent, Typography, Button, Grid } from '@mui/material';
import moment from 'moment';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import RenderSpeaker from './RenderSpeaker';
import _ from 'lodash';
import { useSelector } from 'react-redux';

const WorkshopCard = (props) => {
  const { workshop, handleBookNow, handleCancel, handleShowInterest, handleCancelInterest } = props;
  const allSpeakers = _.sortBy(workshop.speakers, ['created_at']);
  const company = useSelector((state) => state.session.company);
  const email = useSelector((state) => state.session.email);

  const formatDate = (date) => {
    return moment(date).format('MM/DD/YYYY');
  };

  const todayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = mm + '/' + dd + '/' + yyyy;
    return formattedToday;
  };

  const checkRestrictions = (workshopItem) => {
    const { restrictions } = workshopItem;
    const { booking_enabled, booking_start_date, booking_end_date, exclude_target_members } = restrictions ? restrictions : '';
    if (!booking_enabled) {
      return false;
    }
    if (booking_enabled) {
      if (!(formatDate(booking_start_date) <= todayDate() && todayDate() <= formatDate(booking_end_date))) {
        return false;
      }
    }
    if (exclude_target_members) {
      if (company.toLowerCase().includes('target') && email.includes('target.com')) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          borderRadius: '20px',
          overflow: 'visible',
          boxShadow: 'none',
          position: 'relative',
          padding: '20px 0'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <CardContent
            sx={{
              paddingLeft: '4%',
              paddingRight: '4%',
              paddingBottom: '0px !important'
            }}
          >
            <Typography
              variant='body2'
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                pb: 1
              }}
            >
              Time | {moment(workshop.start_time, ['h:mm']).format('hh:mm A')} - {moment(workshop.end_time, ['h:mm']).format('hh:mm A')}
            </Typography>

            <Typography variant='body1' sx={{ pb: 1, textAlign: 'left', fontSize: 22, fontWeight: 600 }}>
              {workshop.name}
            </Typography>
            <Typography variant='body1' sx={{ pb: '10px', textAlign: 'left' }}>
              {workshop.description}
            </Typography>
            {checkRestrictions(workshop) && (
              <Box sx={{ display: 'flex', gap: 4 }}>
                {workshop.userstatus && workshop.userstatus?.status === 'CONFIRMED' ? (
                  <Button
                    type='button'
                    variant='contained'
                    onClick={() => handleCancel(workshop._id)}
                    sx={{
                      backgroundColor: '#be854c',
                      color: '#000',
                      height: 40,
                      '&:focus-visible': {
                        outline: '2px dotted #666666'
                      },
                      '&:hover': {
                        backgroundColor: '#be854c'
                      }
                    }}
                  >
                    Cancel
                  </Button>
                ) : (
                  workshop.booked_count < workshop.no_of_seats && (
                    <Button
                      type='button'
                      variant='contained'
                      onClick={() => handleBookNow(workshop._id)}
                      sx={{
                        backgroundColor: '#be854c',
                        color: '#000',
                        height: 40,
                        '&:focus-visible': {
                          outline: '2px dotted #666666'
                        },
                        '&:hover': {
                          backgroundColor: '#be854c'
                        }
                      }}
                    >
                      Book Now
                    </Button>
                  )
                )}

                {workshop?.userstatus?.status === 'WAITING_LIST' ? (
                  <Button
                    onClick={() => handleCancelInterest(workshop._id)}
                    sx={{
                      '&:focus-visible': {
                        outline: '2px dotted #666666'
                      }
                    }}
                    startIcon={
                      <FavoriteOutlinedIcon
                        sx={{
                          fontSize: '28px !important',
                          color: '#be854c'
                        }}
                      />
                    }
                  >
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: 'bold',
                        color: '#000',
                        textTransform: 'none'
                      }}
                    >
                      I'm interested
                    </span>
                  </Button>
                ) : (
                  workshop?.userstatus?.status !== 'CONFIRMED' &&
                  workshop.booked_count >= workshop.no_of_seats && (
                    <Button
                      onClick={() => handleShowInterest(workshop._id)}
                      sx={{
                        '&:focus-visible': {
                          outline: '2px dotted #666666'
                        }
                      }}
                      startIcon={
                        <FavoriteBorderOutlinedIcon
                          sx={{
                            fontSize: '28px !important',
                            color: '#be854c'
                          }}
                        />
                      }
                    >
                      <span
                        style={{
                          fontSize: 18,
                          fontWeight: 'bold',
                          color: '#000',
                          textTransform: 'none'
                        }}
                      >
                        I'm interested
                      </span>
                    </Button>
                  )
                )}
              </Box>
            )}
            <Grid
              container
              style={{
                justifyContent: 'left',
                marginTop: '20px',
                width: '100%'
              }}
            >
              {allSpeakers &&
                allSpeakers.length > 0 &&
                allSpeakers.map((speakerData, index) => {
                  return (
                    <Grid item md={2} sm={12} key={index} sx={{ mr: 3, width: '100%' }}>
                      <RenderSpeaker speakerData={speakerData} />
                    </Grid>
                  );
                })}
            </Grid>
          </CardContent>
        </Box>
      </Card>
    </>
  );
};

export default WorkshopCard;
