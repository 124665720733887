import { Grid, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import YouTubeIcon from '@mui/icons-material/YouTube';
import X from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
// import { useEffect, useState } from 'react';

// import { Typography } from '@mui/material';
// import { NavHashLink } from 'react-router-hash-link';
// import { closebutton } from '../assets/Images/Images';

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    padding: '20px 0',
    backgroundColor: '#0E141F',
    color: '#FFF !important',
    position: (props) => (props.isPrivacy || props.isFaq ? 'absolute !important' : 'block'),
    // bottom: '0 !important',
    [theme.breakpoints.down('md')]: {
      bottom: 'auto',
    },
  },
  socialIcons: {
    border: '1px solid',
    color: '#FFF',
    borderRadius: '50%',
    padding: '5px',
    fontSize: '1.3rem !important',
    margin: '0 10px',
  },
  footerLinks: {
    // padding: '20px 20px 10px 20px',
    color: '#FFF !important',
    textDecoration: 'none',
    // textAlign: 'center',
    fontSize: '16px',
    margin: 'auto 0 !important',
    [theme.breakpoints.down('md')]: {
      padding: '0px 20px 10px 26px',
    },
  },
  socialIconsContainer: {
    paddingTop: '20px',
  },
  links: {
    '&:focus-visible': {
      // background: '#9B6A42',
      boxShadow: 'none',
      outline: '2px dotted #FFF',
      outlineOffset: 2,
    },
  },
  popUpContainer: {
    backgroundColor: 'rgb(248 225 229)',
    // width: '20%',
    // width: (props) => (props.mobile ? '90%' : '20%'),
    position: 'fixed',
    bottom: '49px',
    right: '20px',
    // marginLeft: (props) => props.mobile && '20px',
    borderRadius: '20px',
    zIndex: '10',
    transition: 'all .6s',
  },
  popupClose: {
    float: 'right',
    margin: '10px',
    cursor: 'pointer',
    textAlign: 'right',
  },
  popupSubHead: {
    lineHeight: 1,
    fontWeight: 'bold !important',
    // marginTop: '10px !important',
    // marginBottom: '10px !important',
  },
  popupSubDesc: {
    fontSize: '15px !important',
    lineHeight: 1,
    fontWeight: '300 !important',
  },
}));
export default function Footer({ isPrivacy, isFaq }) {
  const hideMenu = useSelector((state) => state.invenue.open);
  const mobile = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const classes = useStyles({ isPrivacy, isFaq, mobile });
  // const [showPopup, setShowPopup] = useState(true);
  // const location = useLocation();
  // const notificationStatus = useSelector((state) => state.home.notificationStatus);
  // useEffect(() => {
  //   if (location.pathname === '/' && location.hash === '') {
  //     setShowPopup(true);
  //   } else {
  //     setShowPopup(false);
  //   }
  //   window.scrollTo(0, 0);
  // }, [location]);

  return (
    <>
      <Helmet>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-T7YMEDLNTX'></script>
        <script>
          {`  window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-T7YMEDLNTX');`}
        </script>
        <script type='text/javascript'>
          {`_linkedin_partner_id = "3079162";
                        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                        window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
        </script>
        <script type='text/javascript'>
          {`(function(l) {
                            if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                            window.lintrk.q=[]}
                            var s = document.getElementsByTagName("script")[0];
                            var b = document.createElement("script");
                            b.type = "text/javascript";b.async = true;
                            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                            s.parentNode.insertBefore(b, s);})(window.lintrk);
                    `}
        </script>
        <noscript>{`<img height='1' width='1' style='display:none;' alt='' src='https://px.ads.linkedin.com/collect/?pid=3079162&fmt=gif' />`}</noscript>
      </Helmet>
      {hideMenu ? (
        <></>
      ) : (
        <footer>
          {/* <div
          style={{
            opacity: !showPopup ? '0' : '0.95',
            visibility: !showPopup ? 'hidden' : 'visible',
            width: mobile ? '60%' : '21%',
            marginLeft: mobile && '20px',
          }}
          className={classes.popUpContainer}
        >
          <div className={classes.popupClose} onClick={() => setShowPopup(false)}>
            <img src={closebutton} width='30%' alt='to close pop up' />
          </div>
  
          <div style={{ padding: mobile ? '15px' : '25px' }}>
            <Typography sx={{ fontSize: mobile ? '20px' : '25px', fontWeight: 'bold' }}>Stay updated</Typography>
            <Typography sx={{ fontSize: mobile ? '20px' : '25px', lineHeight: 1, fontWeight: 300 }}>with the latest changes</Typography>
            <Typography className={classes.popupSubHead} style={{ marginTop: '10px', fontSize: mobile ? '15px' : '20px' }}>
              New Speakers <br />
            </Typography>
            <Typography className={classes.popupSubDesc}>
              Check out our new speakers{' '}
              <NavHashLink to='/#speakers' scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                here
              </NavHashLink>{' '}
            </Typography>
            <hr style={{ margin: '4px 0' }} />
            <Typography className={classes.popupSubHead} style={{ fontSize: mobile ? '15px' : '20px' }}>
              Agenda <br />
            </Typography>
            <Typography className={classes.popupSubDesc}>
              Explore our agenda <NavLink to='/agenda'>here</NavLink>
            </Typography>
          </div>
        </div> */}
          <Grid container className={classes.footerContainer} sx={{ position: isPrivacy || isFaq ? 'absolute' : 'block' }}>
            <Grid item md={0.6}></Grid>
            <Grid item md={1.4} xs={12} className={classes.footerLinks} sx={{ marginTop: mobile ? '20px !important' : 0 }}>
              <Link to='/terms-condition' style={{ color: '#fff', textDecoration: 'none' }} className={classes.links}>
                Terms and Conditions
              </Link>
            </Grid>
            <Grid item md={1} xs={12} className={classes.footerLinks}>
              <Link to='/privacy-policy' style={{ color: '#fff', textDecoration: 'none' }} className={classes.links}>
                Privacy Policy
              </Link>
            </Grid>
            <Grid item md={4} xs={12} className={classes.footerLinks}>
              <a href='mailto:elevate@target.com' style={{ color: '#fff', textDecoration: 'none' }} className={classes.links}>
                Contact Us
              </a>
            </Grid>
            <Grid item md={3} xs={12} className={classes.footerLinks} sx={{ padding: { md: '15px 0 0 0', sm: '15px 0 0 0', xs: '8px 0 10px 16px' } }}>
              <a
                href='https://www.linkedin.com/company/target'
                target='_blank'
                // rel='noopener noreferrer'
                aria-label={'Follow us on LinkedIn,opens in new window'}
                className={classes.links}
                rel='noreferrer'
              >
                <LinkedInIcon className={classes.socialIcons} />
              </a>
              <a
                href='https://twitter.com/TeamTargetIndia'
                target='_blank'
                // rel='noopener noreferrer'
                aria-label={'Follow us on twitter'}
                className={classes.links}
                rel='noreferrer'
              >
                <X className={classes.socialIcons} />
              </a>
              <a
                href='https://www.instagram.com/teamtargetinindia/?igshid=YmMyMTA2M2Y%3D'
                target='_blank'
                // rel='noopener noreferrer'
                aria-label={'Follow us on instagram,opens in new window'}
                className={classes.links}
                rel='noreferrer'
              >
                <InstagramIcon className={classes.socialIcons} />
              </a>
              {/* <a
              href='https://www.youtube.com/c/targetelevate'
              target='_blank'
              // rel='noopener noreferrer'
              aria-label={'Follow us on youtube,opens in new window'}
              className={classes.links}
              rel='noreferrer'
              id='youtube_channel_click'
            >
              <YouTubeIcon className={classes.socialIcons} />
            </a> */}
            </Grid>
            <Grid item md={2} xs={12} className={classes.footerLinks}>
              &copy; 2025 Target Brands, Inc.
            </Grid>
          </Grid>
        </footer>
      )}
    </>
  );
}
