import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { InputField, MobileNoInputField } from './Registration';
import { IsEmail, IsValidMobileNo } from '../../Utils/utils';

const modalStyles = {
  customButton: {
    fontSize: 14,
    padding: '12px 26px',
    borderRadius: 50,
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      background: '#333333',
      boxShadow: 'none',
    },
    '&:focus': {
      background: '#9B6A42',
      outline: '1px dotted #000 !important',
      // outlineOffset: 2,
    },
    '&:focus-within': {
      background: '#9B6A42',
      outline: '1px dotted #000 !important',
      // outlineOffset: 2,
    },
    '&:focus-visible': {
      background: '#9B6A42',
      outline: '1px dotted #000 !important',
      // outlineOffset: 2,
    },
    '&:disabled': {
      background: '#D6D6D6',
      color: '#333333',
      cursor: 'default',
    },
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '20px',
  },
};

const ModalForComms = ({ validations, handleSubmit, userData, styles, errors, handleChange, openComms, setOpenComms, setIsCommsCheck }) => {
  function handleClose() {
    setIsCommsCheck(false);
    setOpenComms(false);
  }

  function handleCommsSubmit(e) {
    if (
      userData.mobileForComms !== '' &&
      IsValidMobileNo(userData.mobileForComms) &&
      userData.emailForComms !== '' &&
      IsEmail(userData.emailForComms.toLocaleLowerCase())
    ) {
      setOpenComms(false);
    }
    validations();
  }
  return (
    <div>
      <Modal open={openComms} onClose={handleClose} role='dialog' aria-label='please enter your personal mobile number and  email id'>
        <Box sx={{ ...modalStyles.modalBox }}>
          <MobileNoInputField
            label='Mobile'
            type='text'
            name='mobileForComms'
            value={userData.mobileForComms}
            styles={styles}
            isError={errors.mobileForCommsError}
            handleChange={handleChange}
            placeholder='Enter your mobile number'
            errorLabel={'Please provide your valid mobile number'}
            required={true}
            isComms={true}
            tabIndex={-1}
          />
          <InputField
            label='Personal Email address'
            type='text'
            styles={styles}
            aria-required='true'
            name='emailForComms'
            value={userData.emailForComms}
            handleChange={handleChange}
            isError={errors.emailForCommsError}
            disabled={false}
            required={true}
            placeholder='Enter your personal email address'
            errorLabel={'Please provide your valid personal email address'}
          />
          <div style={{ marginTop: 16 }}>
            <button type='button' onClick={handleClose} style={{ background: '#666', marginRight: '20px', ...modalStyles.customButton }}>
              Cancel
            </button>
            <button onClick={handleCommsSubmit} type='submit' style={{ background: '#CC0000', ...modalStyles.customButton }}>
              Continue
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalForComms;
