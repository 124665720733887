export const states = [
  {
    value: 'Andaman and Nicobar Islands',
    label: 'Andaman and Nicobar Islands',
  },
  {
    value: 'Andhra Pradesh',
    label: 'Andhra Pradesh',
  },
  {
    value: 'Arunachal Pradesh',
    label: 'Arunachal Pradesh',
  },
  {
    value: 'Assam',
    label: 'Assam',
  },
  {
    value: 'Bihar',
    label: 'Bihar',
  },
  {
    value: 'Chandigarh',
    label: 'Chandigarh',
  },
  {
    value: 'Chhattisgarh',
    label: 'Chhattisgarh',
  },
  {
    value: 'Dadra and Nagar Haveli',
    label: 'Dadra and Nagar Haveli',
  },
  {
    value: 'Daman and Diu',
    label: 'Daman and Diu',
  },
  {
    value: 'Delhi',
    label: 'Delhi',
  },
  {
    value: 'Goa',
    label: 'Goa',
  },
  {
    value: 'Gujarat',
    label: 'Gujarat',
  },
  {
    value: 'Haryana',
    label: 'Haryana',
  },
  {
    value: 'Himachal Pradesh',
    label: 'Himachal Pradesh',
  },
  {
    value: 'Jammu and Kashmir',
    label: 'Jammu and Kashmir',
  },
  {
    value: 'Jharkhand',
    label: 'Jharkhand',
  },
  {
    value: 'Karnataka',
    label: 'Karnataka',
  },
  {
    value: 'Kerala',
    label: 'Kerala',
  },
  {
    value: 'Lakshadweep',
    label: 'Lakshadweep',
  },
  {
    value: 'Madhya Pradesh',
    label: 'Madhya Pradesh',
  },
  {
    value: 'Maharashtra',
    label: 'Maharashtra',
  },
  {
    value: 'Manipur',
    label: 'Manipur',
  },
  {
    value: 'Meghalaya',
    label: 'Meghalaya',
  },
  {
    value: 'Mizoram',
    label: 'Mizoram',
  },
  {
    value: 'Nagaland',
    label: 'Nagaland',
  },
  {
    value: 'Odisha',
    label: 'Odisha',
  },
  {
    value: 'Puducherry',
    label: 'Puducherry',
  },
  {
    value: 'Punjab',
    label: 'Punjab',
  },
  {
    value: 'Rajasthan',
    label: 'Rajasthan',
  },
  {
    value: 'Sikkim',
    label: 'Sikkim',
  },
  {
    value: 'Tamil Nadu',
    label: 'Tamil Nadu',
  },
  {
    value: 'Telangana',
    label: 'Telangana',
  },
  {
    value: 'Tripura',
    label: 'Tripura',
  },
  {
    value: 'Uttar Pradesh',
    label: 'Uttar Pradesh',
  },
  {
    value: 'Uttarakhand',
    label: 'Uttarakhand',
  },
  {
    value: 'West Bengal',
    label: 'West Bengal',
  },
];

export const years = [
  {
    value: '0-3',
    label: 'Below 3',
  },
  {
    value: '3-7',
    label: '3-7',
  },
  {
    value: '7-12',
    label: '7-12',
  },
  {
    value: '12+',
    label: 'Above 12',
  },
];

export const advertisementChannels = [
  {
    value: 'emailFromTarget',
    label: 'I received an email from Target',
  },
  {
    value: 'referredByFriend',
    label: 'I was referred by a friend',
  },
  {
    value: 'referredByTargetTM',
    label: 'I was referred by a Target team member',
  },
  {
    value: 'viaSocialMedia',
    label: 'Via social media (LinkedIn/Instagram/Twitter)',
  },
];

export const areasOfInterests = {
  'Application Development': [
    {
      value: 'Java_AD',
      label: 'Java',
      checked: false,
    },
    {
      value: 'UI',
      label: 'UI',
      checked: false,
    },
    {
      value: 'UX',
      label: 'UX',
      checked: false,
    },
    {
      value: 'Android',
      label: 'Android',
      checked: false,
    },
    {
      value: 'iOS',
      label: 'iOS',
      checked: false,
    },
  ],

  'Product Management': [
    {
      value: 'Digital',
      label: 'Digital',
      checked: false,
    },
    {
      value: 'Supply Chain',
      label: 'Supply Chain',
      checked: false,
    },
    {
      value: 'Pricing and Promotions',
      label: 'Pricing and Promotions',
      checked: false,
    },
    {
      value: 'Marketing/ Loyalty',
      label: 'Marketing/ Loyalty',
      checked: false,
    },
    {
      value: 'Finance',
      label: 'Finance',
      checked: false,
    },
    {
      value: 'Marketplace',
      label: 'Marketplace',
      checked: false,
    },
    {
      value: 'Data platform/ Data Science/ Data foundation',
      label: 'Data platform/ Data Science/ Data foundation',
      checked: false,
    },
  ],
  Infrastructure: [
    {
      value: 'Java',
      label: 'Java',
      checked: false,
    },
    {
      value: 'Golang',
      label: 'Golang',
      checked: false,
    },
    {
      value: 'Python_PM',
      label: 'Python',
      checked: false,
    },
    {
      value: 'React JS',
      label: 'React JS',
      checked: false,
    },
    {
      value: 'Docker',
      label: 'Docker',
      checked: false,
    },
    {
      value: 'Kubernetes',
      label: 'Kubernetes',
      checked: false,
    },
    {
      value: 'Kafka',
      label: 'Kafka',
      checked: false,
    },
    {
      value: 'Kotlin',
      label: 'Kotlin',
      checked: false,
    },
    {
      value: 'NetApp Storage',
      label: 'NetApp Storage',
      checked: false,
    },
    {
      value: 'Ansible',
      label: 'Ansible',
      checked: false,
    },
  ],
  'Data Sciences and Data Engineering': [
    {
      value: 'Python',
      label: 'Python',
      checked: false,
    },
    {
      value: 'Scala',
      label: 'Scala',
      checked: false,
    },
    {
      value: 'AI',
      label: 'AI',
      checked: false,
    },
    {
      value: 'ML',
      label: 'ML',
      checked: false,
    },
    {
      value: 'Blockchain',
      label: 'Blockchain',
      checked: false,
    },
    {
      value: 'SQL',
      label: 'SQL',
      checked: false,
    },
    {
      value: 'R',
      label: 'R',
      checked: false,
    },
    {
      value: 'NLP',
      label: 'NLP',
      checked: false,
    },
    {
      value: 'LLM',
      label: 'LLM',
      checked: false,
    },
    {
      value: 'Stats Modelling',
      label: 'Stats Modelling',
      checked: false,
    },
  ],
  Cybersecurity: [
    {
      value: 'SOX',
      label: 'SOX',
      checked: false,
    },
    {
      value: 'CISA',
      label: 'CISA',
      checked: false,
    },
    {
      value: 'CISSP',
      label: 'CISSP',
      checked: false,
    },
    {
      value: 'CISM',
      label: 'CISM',
      checked: false,
    },
    {
      value: 'Incident Response',
      label: 'Incident Response',
      checked: false,
    },
    {
      value: 'SIEM',
      label: 'SIEM',
      checked: false,
    },
    {
      value: 'SOAR',
      label: 'SOAR',
      checked: false,
    },
    {
      value: 'Threat Hunting',
      label: 'Threat Hunting',
      checked: false,
    },
    {
      value: 'SOC',
      label: 'SOC',
      checked: false,
    },
  ],
};
