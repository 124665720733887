import { CardMedia, Grid, useMediaQuery } from '@mui/material';
import { Typography } from '@mui/material';
import { headerBannerMobile } from '../assets/Images/Images';

const HeaderBanner = ({ marginTop, headerTxt, headerBannerBg }) => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const tab = useMediaQuery((theme) => theme.breakpoints.between(768, 1023));
  const windowsLaptop = useMediaQuery((theme) => theme.breakpoints.between(1024, 1700));
  const desktop = useMediaQuery((theme) => theme.breakpoints.between(1701, 2560));
  const bannerTexts = {
    register: {
      line1: 'Tell us',
      line2: 'about yourself',
    },
    gallery: {
      line1: 'Gallery',
      line2: '',
    },
    agenda: {
      line1: 'Agenda 2023',
      line2: (
        <Typography style={{ paddingTop: '20px', textAlign: 'center', color: '#FFF', fontSize: '16px' }}>
          Click{'  '}
          {
            <a
              style={{ color: '#FFF', fontFamily: 'Helvetica For TargetBold' }}
              target='_blank'
              href='https://gfc.target.com/elevatewebsiteproduction/elevate_2023/Agenda_target_elevate_2023_R2_accessible.pdf'
              rel='noreferrer'
            >
              here
            </a>
          }
          {'  '} to download the full agenda.
        </Typography>
      ),
    },
    termsandconditions: {
      line1: 'Terms',
      line2: 'and Conditions',
    },
    privacypolicy: {
      line1: 'Privacy',
      line2: 'Policy',
    },
    faqs: {
      line1: 'Frequently',
      line2: 'asked questions',
    },
  };

  return (
    <Grid container style={{ marginTop: marginTop, display: 'block' }}>
      <Grid item>
        <div style={{ position: 'relative' }}>
          <CardMedia
            aria-label='header banner'
            component='img'
            height={mobile || tab || windowsLaptop ? '226px' : 'auto'}
            image={mobile ? headerBannerMobile : headerBannerBg}
            alt='header banner'
          />
          <div
            style={{
              position: 'absolute',
              color: 'white',
              // top: desktop ? '136px' : mobile ? '50px' : tab ? '45px' : windowsLaptop ? '45px' : '30px',
              left: desktop ? '5%' : mobile ? '8%' : tab || windowsLaptop ? '8%' : '10%',
              lineHeight: 1,
              top: mobile && bannerTexts[headerTxt]['line2'] === '' ? '35%' : mobile ? '20%' : '36%',
            }}
          >
            <span
              style={{
                fontSize: mobile || tab || windowsLaptop ? '32px' : '50px',
                fontFamily: 'Helvetica For Target',
                fontWeight: bannerTexts[headerTxt]['line2'] === '' && 'bold',
              }}
              role='heading'
              aria-level='2'
            >
              {bannerTexts[headerTxt]['line1']}
            </span>
            <br />
            {headerTxt === 'agenda' ? (
              <span
                style={{
                  width: '100px',
                  display: 'block',
                  border: '2px solid #FFF',
                  backgroundColor: '#FFF',
                  marginTop: '10px',
                }}
              ></span>
            ) : null}
            <span
              style={{
                fontSize: mobile || tab || windowsLaptop ? '32px' : '50px',
                fontFamily: 'Helvetica For Target',
                fontWeight: 800,
              }}
            >
              {bannerTexts[headerTxt]['line2']}
            </span>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default HeaderBanner;
