import React from 'react';
import { Box, TextField, Button, InputAdornment } from '@mui/material';
import { crossSvg } from '../../assets/Images/Images';

const LoginPopup = (props) => {
  const {
    formData,
    usernameRef,
    passwordRef,
    handleChange,
    handleBlur,
    handleForgotPass,
    //handleRedirect,
    handleContinue,
    handleShowText,
    errors,
    showText,
    loginErrorData
  } = props;
  const styles = {
    customButton: {
      fontSize: 14,
      padding: '12px 26px',
      borderRadius: 50,
      background: '#333333',
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
      '&:hover': {
        background: '#d61b32',
        boxShadow: 'none'
      },
      '&:focus-visible': {
        background: '#d61b32',
        boxShadow: 'none',
        outline: '2px dotted #666666',
        outlineOffset: 2
      },
      '&:disabled': {
        background: '#D6D6D6',
        color: '#333333',
        cursor: 'default'
      }
    },
    loginButton: {
      fontSize: 14,
      padding: '12px 26px',
      border: '1.5px solid #666666',
      borderRadius: 50,
      background: '#ffffff',
      color: '#000000',
      cursor: 'pointer',
      '&:hover': {
        background: '#333333',
        boxShadow: 'none',
        color: '#FFF'
      },
      '&:focus': {
        background: '#333333',
        boxShadow: 'none',
        color: '#FFF',
        outline: '2px dotted #666666',
        outlineOffset: 2
      },
      '&:disabled': {
        background: '#D6D6D6',
        color: '#333333',
        cursor: 'default'
      }
    },
    cancelButton: {
      fontSize: 14,
      padding: '12px 26px',
      borderRadius: 50,
      background: '#ffffff',
      color: '#333333',
      border: '1.5px solid #666666',
      cursor: 'pointer',
      '&:hover': {
        background: '#333333',
        boxShadow: 'none',
        color: '#FFF'
      },
      '&:focus-visible': {
        background: '#333333',
        boxShadow: 'none',
        color: '#FFF',
        outline: '2px dotted #666666',
        outlineOffset: 2
      }
    },
    closeButton: {
      border: 'none',
      width: '20px',
      height: '20px',
      background: `url(${crossSvg}) no-repeat`,
      cursor: 'pointer',
      '&:focus-visible': {
        // background: '#D6D6D6',
        outline: '2px dotted #666666',
        outlineOffset: 2
      },
      '&:hover': {
        opacity: 0.75
      }
    },
    root: {
      '& .MuiOutlinedInput-root': {
        color: '#000000',
        height: 50,
        '& fieldset': {
          borderRadius: `50px`,
          border: '2px solid #be854c',
          fontSize: 14,
          color: '#000000'
        },
        '&.Mui-focused': {
          borderRadius: `50px`,
          outline: '2px dotted #666666',
          outlineOffset: 2,
          color: '#000000 !important'
        }
      },
      '& .MuiOutlinedInput-input': {
        textIndent: 9,
        color: '#000000'
      },
      '& .MuiInputBase-input': {
        color: '#000000'
      }
    },
    forgotPassText: {
      fontSize: 14,
      padding: '14px',
      background: '#ffffff',
      border: '1px solid #333333',
      color: '#333333',
      cursor: 'pointer',
      borderRadius: 50,
      textTransform: 'none',
      fontWeight: 700,
      '&:hover': {
        background: '#333333',
        color: '#fff',
        borderRadius: 50
      },
      '&:focus-visible': {
        background: '#333333',
        color: '#fff',
        borderRadius: 50,
        outline: '2px dotted #666666',
        outlineOffset: 2
      }
    },
    showText: {
      fontSize: 14,
      color: '#666666',
      cursor: 'pointer',
      background: '#ffffff',
      border: 'none',
      '&:focus-visible': {
        background: '#D6D6D6',
        outline: '2px dotted #666666',
        outlineOffset: 2,
        borderRadius: 50
      },
      textTransform: 'none'
    },
    loginText: {
      marginTop: 0,
      color: '#333333',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 44,
      marginBottom: 0,
      paddingTop: '10%',
      fontWeight: 400
    },
    ButtonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '22px',
      gap: '50px'
    },
    dialog: {
      '& .MuiDialog-paper': {
        maxWidth: 479
      }
    },
    firstLoginText: {
      color: '#666666',
      fontSize: 16,
      background: '#ffffff',
      border: 'none',
      cursor: 'pointer',
      margin: '30px 0',
      textTransform: 'none'
    },
    footerLine: {
      color: '#333333',
      fontSize: 12,
      padding: '10px 30px',
      textAlign: 'center'
    },
    labelStyles: {
      fontSize: 16,
      mb: '4px',
      ml: 2,
      fontWeight: 'bold',
      color: '#000'
    },
    underLine: {
      border: '2px solid #be854c',
      backgroundColor: '#be854c',
      width: 80,
      marginBottom: '30px'
    },
    errorLabel: {
      textAlign: 'center',
      color: '#e92e1a',
      fontWeight: 'bold',
      fontSize: 14,
      paddingTop: 0.5
    },
    crossMark: {
      height: 17,
      width: 17,
      backgroundColor: '#ffffff',
      '&:hover': {
        opacity: 0.75,
        backgroundColor: '#fff'
      }
    }
  };

  return (
    <Box>
      <form id='loginForm' onSubmit={(e) => handleContinue(e)}>
        <h2 style={styles.loginText}>Login</h2>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box sx={styles.underLine} />
        </Box>
        <Box sx={styles.labelStyles}>
          {'User ID'}
          <span aria-hidden='true'>*</span>
        </Box>
        <TextField
          fullWidth
          type={'text'}
          name={'username'}
          placeholder={'Enter your email here'}
          onChange={(e) => handleChange(e)}
          onBlur={(e) => handleBlur(e)}
          value={formData.username}
          sx={styles.root}
          inputRef={usernameRef}
        />
        <Box role='alert' sx={styles.errorLabel} style={{ paddingBottom: 26 }}>
          {errors.usernameError}
        </Box>

        <Box sx={styles.labelStyles}>
          {'Password'}
          <span aria-hidden='true'>*</span>
        </Box>
        <TextField
          fullWidth
          type={showText.isPass ? 'text' : 'password'}
          name={'password'}
          placeholder={'Enter your password here'}
          onChange={(e) => handleChange(e)}
          value={formData.password}
          sx={styles.root}
          inputRef={passwordRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Button sx={styles.showText} type='Button' onClick={() => handleShowText('isPass', showText.isPass)}>
                  {showText.isPass ? 'Hide' : 'Show'}
                </Button>
              </InputAdornment>
            )
          }}
        />
        <Box role='alert' sx={styles.errorLabel}>
          {errors.passwordError}
        </Box>

        {loginErrorData.message ? (
          <Box role='alert' sx={styles.errorLabel} style={{ paddingTop: 20 }}>
            {loginErrorData.message}
          </Box>
        ) : (
          ''
        )}
        {/* <Box role="alert" sx={styles.errorLabel} style={{ paddingTop: 20 }}>
            {`New to Elevate? `}
            <span
              style={{
                color: "#e92e1a",
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={() => handleRedirect()}
            >
              Click here
            </span>
            {` to sign up`}
          </Box> */}
        <Box sx={styles.ButtonContainer}>
          <Button type='button' sx={styles.forgotPassText} onClick={() => handleForgotPass()} style={{ padding: '12px 26px' }}>
            Forgot password?
          </Button>
          <Button
            sx={styles.customButton}
            type='submit'
            // disabled={loginErrorData ? true : false}
            style={{ padding: '12px 26px', textTransform: 'none' }}
          >
            Continue
          </Button>
        </Box>
      </form>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          //  onClick={() => handleFirstTimeLogin()}
          sx={styles.firstLoginText}
        >
          {'First time logging in? Click here'}
        </Button>
      </Box>
      <hr color='#be854c' aria-hidden={true} />
      {/* <Box sx={styles.footerLine}>
  {`Membership to Target Elevate is currently invite only. 
  If you have already signed up, look out for an email 
  from elevate@target.com for details`}
</Box> */}
    </Box>
  );
};

export default LoginPopup;
