import { useUnsubscribeUserMutation } from '../../api/unsubscribe';
import { useEffect } from 'react';
import { Dialog, DialogContent, Button } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
// import Popup from './Popup';

const Unsubscribe = ({ setOpenUnsubscribe, openUnsubscribe, event_Id, userEmail_Id }) => {
  const [unsubscribeUser, { data: unsubscribeUserResponse, error: unsubscribeUserResError }] = useUnsubscribeUserMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (unsubscribeUserResponse) {
      setOpenUnsubscribe(false);
      navigate('/');
    }
    if (unsubscribeUserResError) {
      setOpenUnsubscribe(false);
      // <Popup isError={true} open={true} />;
    }
  }, [navigate, setOpenUnsubscribe, unsubscribeUserResError, unsubscribeUserResponse]);

  const handleSubmit = () => {
    try {
      unsubscribeUser({
        eventId: event_Id,
        userEmailId: userEmail_Id,
      });
    } catch (error) {
      console.log(' in error ', error);
    }
  };
  const handleClose = () => {
    setOpenUnsubscribe(false);
  };

  return (
    <Dialog
      open={openUnsubscribe}
      sx={{
        width: '30%',
        margin: '0 auto',
        '& .MuiPaper-root': {
          borderRadius: '25px',
          width: '100%',
          boxShadow: 'none',
        },
        '& .MuiBackdrop-root ': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
      }}
      onClose={handleClose}
      role='dialog'
      aria-modal='true'
      aria-label={`are you sure to unsubscribe`}
    >
      <DialogContent
        style={{
          padding: '40px',
          borderBottom: 'none',
          overflow: 'unset',
          paddingBottom: '10px',
          borderRadius: '25px',
          textAlign: 'center',
        }}
      >
        If you do not consent, you will stop receiving updates about Target Elevate in your inbox and will not be able to access the event. Would you
        like to continue?
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '25px' }}>
        <Button
          sx={{
            textTransform: 'none',
            minWidth: '128px',
            backgroundColor: '#cc0000',
            color: '#fff',
            '&:hover ': { backgroundColor: '#cc0000', color: '#fff' },
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button
          sx={{
            minWidth: '128px',
            textTransform: 'none',
            backgroundColor: 'rgb(102, 102, 102)',
            color: '#fff',
            '&:hover ': { backgroundColor: 'rgb(102, 102, 102)', color: '#fff' },
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Unsubscribe;
