export const logo = 'https://gfc.target.com/marketing/Elevate25/logo_elevate.png';
export const crossSvg = 'https://gfc.target.com/elevatewebsiteproduction/cross.svg';
export const TickMark = 'https://gfc.target.com/elevatewebsiteproduction/Tick-Mark-Once.gif';
export const headerBannerBg = 'https://gfc.target.com/marketing/Elevate25/reg_desktop.png';
export const headerBannerMobile = 'https://gfc.target.com/marketing/Elevate25/mob_test.png';
export const unsubscribed = 'https://gfc.target.com/stencil/nonprod/1704368260826_tick_icon.png';
export const closebutton = 'https://gfc.target.com/elevatewebsiteproduction/closebutton.png';
export const heroMobile = 'https://gfc.target.com/marketing/Elevate25/hero-mobile.mp4';
export const heroDesktop = 'https://gfc.target.com/marketing/Elevate25/hero-desktop.mp4';
export const consentPdf = 'https://gfc.target.com/marketing/Elevate25/Elevate 2025 Consent form.pdf';
