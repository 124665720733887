import React, { useState } from 'react';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import ViewSpeaker from '../../shared/ViewSpeaker';

const RenderSpeaker = (props) => {
  const { speakerData } = props;
  const [speakerForView, setSpeakerForView] = useState({});

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = (speaker) => {
    setOpenDialog(true);
    setSpeakerForView(speaker);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Card
        // id={workshopData.id}
        sx={{
          display: 'flex',
          borderRadius: '20px',
          margin: 0,
          padding: '2%',
          overflow: 'visible',
          position: 'relative',
          boxShadow: 'none',
          cursor: 'pointer'
        }}
        onClick={() => handleClickOpen(speakerData)}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <CardMedia
            aria-label={speakerData.user.name}
            component='img'
            sx={{
              width: 150,
              height: 150,
              borderRadius: '50%',
              margin: 'auto'
            }}
            image={speakerData.engagement_image}
            // alt={fullName}
          />
          <CardContent sx={{ textAlign: 'center', padding: '26px 0' }}>
            <Typography variant='h3' sx={{ fontSize: 18 }}>
              {speakerData.user.name}
            </Typography>
            <Typography variant='body1' sx={{ fontSize: 15, color: '#666666' }}>
              {speakerData.meta.designation}
            </Typography>
            <Typography
              variant='h3'
              sx={{
                fontSize: 15,
                color: '#666666'
              }}
            >
              {speakerData.user.company}
            </Typography>
          </CardContent>
        </Box>
      </Card>
      <ViewSpeaker
        open={openDialog}
        onClose={handleClose}
        name={speakerForView?.user?.name}
        company={speakerForView?.user?.company}
        designation={speakerForView?.meta?.designation}
        bio={speakerForView?.meta?.bio}
        image={speakerForView?.engagement_image}
      />
    </>
  );
};

export default RenderSpeaker;
