import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import styles from './HeroBanner.module.css';
import { useNavigate } from 'react-router-dom';
import { heroDesktop, heroMobile } from '../../assets/Images/Images';

const HeroBanner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTab = useMediaQuery(theme.breakpoints.between(600, 1025));
  //   const isDesktop = useMediaQuery(theme.breakpoints.up(1025));
  const BannerText = () => {
    const navigate = useNavigate();
    function handleClick() {
      if (localStorage.getItem('utm_source')) {
        navigate('/register');
      } else {
        navigate('/register?utm_source=website');
      }
    }
    return (
      <Box>
        <Box sx={{ mb: { sm: '10px', xs: '10px', md: '20px' } }}>
          <Typography
            variant='body'
            className={styles['banner-title']}
            sx={{ fontSize: { md: '48px', sm: '45px', xs: '25px' } }}
            role='heading'
            aria-level='2'
          >
            Empowering <br /> Women in Tech
          </Typography>
        </Box>
        <Box sx={{ mb: { md: '20px', sm: '10px', xs: '10px' }, fontSize: { md: '24px', sm: '24px', xs: '20px' } }}>
          <Typography variant='body' className={styles['banner-subtitle']}>
            Learn, network, be inspired
          </Typography>
        </Box>
        <Box sx={{ mb: { md: '20px', sm: '10px', xs: '10px' }, width: { md: '95%', sm: '45%', xs: '72%' } }}>
          {isMobile ? (
            <Typography variant='body' className={styles['banner-copy']} component='p'>
              Join a community of women in tech at Target Elevate 2025 to unlock opportunities for professional growth and connection.
              <br /> Read more{' '}
              <a
                href='/why-elevate'
                style={{ color: '#fff' }}
                aria-label='highlights from past editions of Target Elevate'
                id='home_highlights_click'
                rel='noreferrer'
              >
                here
              </a>
              .​
            </Typography>
          ) : (
            <Typography variant='body' className={styles['banner-copy']} component='p'>
              Join a community of women in tech at Target Elevate 2025 to unlock opportunities for professional growth and connection. Read more{' '}
              <a
                href='/why-elevate'
                style={{ color: '#fff' }}
                aria-label='highlights from past editions of Target Elevate'
                id='home_highlights_click'
                rel='noreferrer'
              >
                here
              </a>
              .​
            </Typography>
          )}
        </Box>
        <Box sx={{ mb: { md: '20px', sm: '10px', xs: '10px' } }}>
          <Button
            onClick={handleClick}
            variant='contained'
            id='register_click'
            sx={{
              backgroundColor: '#cc0000',
              backgroundImage: 'linear-gradient(90deg, rgba(223, 0, 0, 1) 0%, rgba(223, 0, 0, 1) 20%, rgba(139, 0, 0, 1) 100%)',
              color: '#fff',
              borderRadius: '20px',
              textTransform: 'none',
              padding: '7px 40px',
              '&:hover': {
                backgroundColor: '#cc0000',
              },
            }}
          >
            Request an invitation
          </Button>
        </Box>
        <Box
          sx={{
            color: '#fff',
            display: 'flex',
            // mt: { sm: '0', xs: '0', md: '20px' },
            mb: { md: '20px', sm: '10px', xs: '10px' },
            alignItems: 'center',
          }}
        >
          <Box sx={{ fontSize: '20px', p: '0px 20px 0px 0' }}>
            <Typography variant='body' sx={{ fontWeight: 'bold' }}>
              Feb 21
            </Typography>
            <br />
            <Typography variant='body'>2025</Typography>
          </Box>
          {/* <hr width='1' size='200' style={{ style: '0 auto' }} /> */}
          <Box sx={{ fontSize: '20px', borderLeft: '2px solid #fff', marginTop: '0px', height: '40px' }}></Box>
          <Box sx={{ fontSize: '20px', p: '0px 20px 0px 20px' }}>
            <Typography variant='body' sx={{ fontWeight: 'bold' }}>
              Conrad
            </Typography>
            <br />
            <Typography variant='body'>Bengaluru</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            color: '#fff',
            mt: '0px',
            '& a': {
              color: '#fff',
            },
          }}
        >
          {/* <Typography variant='body'>
            Catch glimpses of Target Elevate <a href='/gallery'>here</a>{' '}
          </Typography> */}
        </Box>
      </Box>
    );
  };
  return (
    <Grid container>
      <Grid item md={12}>
        {/* <Box sx={{ position: 'relative', overflow: 'hidden', height: { md: '579px', sm: '800px', xs: '900px' } }}> */}
        <Box sx={{ position: 'relative', overflow: 'hidden' }}>
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              position: 'relative',
              width: '100%',
            }}
          >
            <source src={isMobile || isTab ? heroMobile : heroDesktop} type='video/mp4' />
          </video>
          <Box
            sx={{
              position: 'absolute',
              top: isMobile ? '34%' : isTab ? '60%' : '25%', // { isDesktop: '20%', isMobile: '31%', isTab: '31%' },
              left: '5%',
              zIndex: '1', // Pl    ace above the video
            }}
          >
            <Grid item md={5.5} xs={11.9}>
              <BannerText />
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HeroBanner;
