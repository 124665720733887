import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { PageApi, ConfigApi, DynamicContent } from 'enterprise-stencil';
import { Loginapi } from './api/login';
import { sessionSlice } from './api/session';
import { WorkshopApi } from './api/workshop';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { MentorshipApi } from './api/mentorship';
import { Tenantapi, tenantSlice } from './api/tenant';
import { ValidateUserApi, userSlice } from './api/validateUser';
import { RegistrationApi } from './api/registration';
import { inVenueApi, inVenueSlice } from './api/invenue';
import { UnsubscribeApi } from './api/unsubscribe';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [sessionSlice.name, tenantSlice.name]
};

const reducers = combineReducers({
  [PageApi.reducerPath]: PageApi.reducer,
  [DynamicContent.reducerPath]: DynamicContent.reducer,
  [ConfigApi.reducerPath]: ConfigApi.reducer,
  [Loginapi.reducerPath]: Loginapi.reducer,
  [sessionSlice.name]: sessionSlice.reducer,
  [WorkshopApi.reducerPath]: WorkshopApi.reducer,
  [MentorshipApi.reducerPath]: MentorshipApi.reducer,
  [Tenantapi.reducerPath]: Tenantapi.reducer,
  [tenantSlice.name]: tenantSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [ValidateUserApi.reducerPath]: ValidateUserApi.reducer,
  [RegistrationApi.reducerPath]: RegistrationApi.reducer,
  [inVenueSlice.name]: inVenueSlice.reducer,
  [inVenueApi.reducerPath]: inVenueApi.reducer,
  [UnsubscribeApi.reducerPath]: UnsubscribeApi.reducer
});
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat([
      thunk,
      PageApi.middleware,
      DynamicContent.middleware,
      ConfigApi.middleware,
      Loginapi.middleware,
      ValidateUserApi.middleware,
      RegistrationApi.middleware,
      WorkshopApi.middleware,
      MentorshipApi.middleware,
      Tenantapi.middleware,
      UnsubscribeApi.middleware,
      inVenueApi.middleware
    ])
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);
