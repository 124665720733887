import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiConfig from '../apiConfig';

const { api } = apiConfig;

export const MentorshipApi = createApi({
  reducerPath: 'MentorshipApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api.host,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().session.authToken;
      if (token) {
        headers.set('authorization', token);
      }
      return headers;
    }
  }),
  tagTypes: ['Mentorships'],
  endpoints: (builder) => ({
    getActiveMentorships: builder.query({
      query: ({ eventId }) => ({
        url: `content/events/${eventId}/activementorships`,
        params: {
          key: api.key
        }
      }),
      providesTags: ['Mentorships']
    }),
    bookMentorship: builder.mutation({
      query: ({ eventId, mentorshipId, speakerid, slotid }) => ({
        url: `content/events/${eventId}/mentorships/${mentorshipId}/book`,
        method: 'POST',
        params: {
          key: api.key
        },
        body: {
          speakerid: speakerid,
          slotid: slotid
        }
      }),
      invalidatesTags: ['Mentorships']
    }),
    cancelMentorship: builder.mutation({
      query: ({ eventId, mentorshipId, speakerid, slotid }) => ({
        url: `content/events/${eventId}/mentorships/${mentorshipId}/cancel`,
        method: 'POST',
        params: {
          key: api.key
        },
        body: {
          speakerid: speakerid,
          slotid: slotid
        }
      }),
      invalidatesTags: ['Mentorships']
    }),
    cancelInterest: builder.mutation({
      query: ({ eventId, mentorshipId, speakerid }) => ({
        url: `content/events/${eventId}/mentorships/${mentorshipId}/cancelinterest`,
        method: 'POST',
        params: {
          key: api.key
        },
        body: {
          speakerid: speakerid
        }
      }),
      invalidatesTags: ['Mentorships']
    }),
    showInterest: builder.mutation({
      query: ({ eventId, mentorshipId, speakerid }) => ({
        url: `content/events/${eventId}/mentorships/${mentorshipId}/showinterest`,
        method: 'POST',
        params: {
          key: api.key
        },
        body: {
          speakerid: speakerid
        }
      }),
      invalidatesTags: ['Mentorships']
    })
  })
});

export const {
  useGetActiveMentorshipsQuery,
  useBookMentorshipMutation,
  useCancelMentorshipMutation,
  useCancelInterestMutation,
  useShowInterestMutation
} = MentorshipApi;
