import React from 'react';
import { Box, Dialog, Button, DialogContent, DialogContentText, Typography, Slide } from '@mui/material';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const ViewSpeaker = (props) => {
  const { open, onClose, name, company, designation, bio, image } = props;
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      sx={{
        width: '30%',
        margin: '0 auto',
        '& .MuiPaper-root': {
          borderRadius: '25px',
          boxShadow: 'none'
        },
        '& .MuiBackdrop-root ': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
      }}
      onClose={onClose}
      role='dialog'
      aria-modal='true'
      aria-label={`bio`}
    >
      <Box>
        <Button
          sx={{
            float: 'right',
            right: '20px',
            top: '10px',
            position: 'absolute',
            fontSize: '30px !important',
            cursor: 'pointer',
            color: '#333333 !important',
            border: 'none',
            padding: '3px',
            backgroundColor: 'transparent'
          }}
          onClick={onClose}
          aria-label='close button'
        >
          <CloseSharpIcon onClick={onClose} />
        </Button>
      </Box>
      <img src={image} alt={'test'} style={{ width: '50%', margin: '45px auto' }} />
      <DialogContent
        style={{
          padding: '0px 40px 40px 40px',
          borderBottom: '10px solid #a3683a',
          overflow: 'unset',
          borderRadius: '25px'
        }}
      >
        <DialogContentText id='alert-dialog-slide-description'>
          <Box>
            <Typography
              variant='h1'
              align='center'
              sx={{
                fontSize: '22px !important',
                padding: '0 0 6px 0',
                color: '#986A42'
              }}
            >
              {name}
            </Typography>
            <Typography
              variant='body2'
              align='center'
              sx={{
                fontWeight: '300 !important',
                color: '#666666 ',
                fontSize: '16px !important'
              }}
            >
              {designation}, {company}
            </Typography>
            <Typography
              variant='body2'
              align='center'
              sx={{
                fontSize: '15px !important',
                textAlign: 'left !important'
              }}
            >
              {bio}
            </Typography>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ViewSpeaker;
