import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Video from '../../Components/Video/VideoPlayer';
import { Helmet } from 'react-helmet';

const styles = {
  topicName: {
    fontWeight: 'bold',
    lineHeight: '1.5em',
    // fontFamily: 'Helvetica For TargetBold',
    margin: 0,
    padding: 0,
    color: '#6d6d6d',
  },
  topicTitle: {
    padding: '20px 0 6px 0',
    margin: 0,
    fontSize: 22,
    // fontFamily: 'Helvetica For TargetBold',
    lineHeight: 1,
  },
  topicBio: {
    lineHeight: '1.5em',
    padding: 0,
    margin: 0,
    // fontFamily: 'Helvetica For TargetRegular',
    fontSize: 16,
  },
  image: {
    width: '100%',
    cursor: 'pointer',
  },
};

const RenderVideos = ({ classes, galleryVideos: videos }) => {
  const [open, setOpen] = useState(false);
  const [url, setURL] = useState('');
  const [captions, setCaptions] = useState('');
  const mobile = useMediaQuery('(max-width:899px)');
  const [title, setTitle] = useState('');
  const [poster, setPoster] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [videosToRender, setVideosToRender] = useState();

  useEffect(() => {
    setVideosToRender([...videos]);
  }, [videos]);

  const handleClick = (videoObj) => {
    setURL(videoObj.videoUrl);
    setCaptions(videoObj.captions);
    setPoster(videoObj.url);
    setVideoTitle(videoObj.title);
    setOpen(true);
    setTitle(videoObj.name + ' video');
  };
  const handleClose = () => {
    setOpen(false);
    setTitle('Gallery');
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>

        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript>
          {`
                    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WLZJSJZ"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
        {/* <!-- End Google Tag Manager (noscript) --> */}
      </Helmet>
      <Grid container>
        {videosToRender &&
          videosToRender.map((video, index) => (
            <Grid item lg={4} md={6} sm={12} padding={!mobile ? '1rem' : '1.5rem'} key={`${index}-${video.url}`}>
              <button style={{ border: 'none', padding: 0, background: '#ffffff' }} onClick={() => handleClick(video)}>
                <img
                  src={video.url}
                  alt={video.name}
                  aria-label={'play video of ' + video.name + ' opens in a modal'}
                  title={'play video of ' + video.name + ' opens in a modal'}
                  className={classes.image}
                />
              </button>
              <h2 className={classes.topicTitle}>{video.title}</h2>
              <p className={classes.topicName}>
                {video.name} {video.designation && <span className={classes.topicName}>| {video.designation}</span>}
              </p>
              {video.name1 ? <p className={classes.topicName}>{video.name1}</p> : null}

              <p className={classes.topicBio}>{video.bio}</p>
            </Grid>
          ))}
        <Video open={open} handleClose={handleClose} videoSrc={url} captions={captions} poster={poster} videoTitle={videoTitle} />
      </Grid>
    </>
  );
};

export default withStyles(styles)(RenderVideos);
